<template>
  <div v-if="isVisible" class="modal-background">
    <div class="modal-content">
      <img :src="photoUrl" alt="Large Photo" />
      <button @click="closeModal">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['photoUrl'],
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
}

.modal-content img {
  max-width: 100%;
  max-height: 100%;
}
</style>