<template>
  <div>

      <table id="simple-table" class="table  table-hover">
        <tr>
         <td  class="col-sm-1"> <label class="control-label">媒体类型</label></td>
          <td  class="col-sm-2">
            <select     v-model="mediaselect" class="form-control">
              <option value="">所有</option>
            <option v-for="o in MEDIA_TYPE" v-bind:value="o.key">{{o.value}}</option>
          </select></td>

          <td  class="col-sm-3"><button v-on:click="list(1)" class="btn btn-white btn-default btn-round">
            <i class="ace-icon fa fa-refresh"></i>
            刷新
          </button> </td>

          <td  class="col-sm-3">  <button v-on:click="add" class="btn btn-white btn-default btn-round">
            <i class="ace-icon fa fa-edit"></i>
            新增
          </button>
          </td>

        </tr>
      </table>

    <pagination ref="pagination" v-bind:list="list" v-bind:itemCount="8"></pagination>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>标题</th>
<!--        <th>课程</th>-->
<!--        <th>大章</th>-->
        <th>媒体</th>
        <th>时长</th>
<!--        <th>收费</th>-->
        <th>顺序</th>
        <th>上传人</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="section in sections">
        <td  class="col-sm-2">{{section.id}}</td>
        <td class="col-sm-3">{{section.title}}</td>
<!--        <td>{{section.courseId}}</td>-->
<!--        <td>{{section.chapterId}}</td>-->
        <td  class="col-sm-1">{{MEDIA_TYPE | optionKV(section.media)}}</td>
        <td  class="col-sm-1">{{section.time}}</td>
<!--        <td>{{section.charge}}</td>-->
        <td class="col-sm-1">{{section.sort}}</td>
        <td class="col-sm-1">{{section.submitter}}</td>

      <td class="col-sm-3">
        <div class="hidden-sm hidden-xs btn-group">
          <button v-show="section.vod" v-on:click="play(section)" class="btn btn-xs btn-info">
            播放
          </button>
          <button v-on:click="edit(section)" class="btn btn-xs btn-warning">
            编辑
          </button>
          <button v-show="section.loginName === user.loginName" v-on:click="del(section.id)" class="btn btn-xs btn-danger">
            删除
          </button>
        </div>
      </td>
      </tr>
      </tbody>
    </table>

    <div id="form-modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">表单</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-2 control-label">标题</label>
                <div class="col-sm-10">
                  <input v-model="section.title" class="form-control">
                </div>
              </div>
<!--              <div class="form-group">-->
<!--                <label class="col-sm-2 control-label">课程</label>-->
<!--                <div class="col-sm-10">-->
<!--                  <input v-model="section.courseId" class="form-control">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="form-group">-->
<!--                <label class="col-sm-2 control-label">大章</label>-->
<!--                <div class="col-sm-10">-->
<!--                  <input v-model="section.chapterId" class="form-control">-->
<!--                </div>-->
<!--              </div>-->




              <div  v-show ="section.media===MEDIA_TYPE.VIDEO.key" class="form-group">
                <label class="col-sm-2 control-label">时长</label>
                <div class="col-sm-10">
                  <input v-model="section.time" class="form-control">
                </div>
              </div>
<!--              <div class="form-group">-->
<!--                <label class="col-sm-2 control-label">收费</label>-->
<!--                <div class="col-sm-10">-->
<!--                  <input v-model="section.charge" class="form-control">-->
<!--                </div>-->
<!--              </div>-->
              <div class="form-group">
                <label class="col-sm-2 control-label">顺序</label>
                <div class="col-sm-10">
                  <input v-model="section.sort" class="form-control">
                </div>
              </div>
              <div v-show ="section.media===MEDIA_TYPE.VIDEO.key" class="form-group">
                <label class="col-sm-2 control-label">视频</label>
                <div class="col-sm-10">
                  <vod v-bind:input-id="'video-upload'"
                       v-bind:text="'上传VOD'"
                       v-bind:suffixs="['mp4']"
                       v-bind:use="'album/video'"
                       v-bind:after-upload="afterUploadVideo"></vod>
                  <div v-show="section.vod" class="row">
                    <div class="col-md-9">
                      <player v-bind:player-id="'form-player-div'"
                               ref="player"></player>
                      <video v-bind:src="section.video" id="video" controls="controls" class="hidden"></video>
                    </div>
                  </div>
                </div>
              </div>

              <div v-show ="section.media===MEDIA_TYPE.VIDEO.key" class="form-group">
                <label class="col-sm-6 control-label">视频vod</label>
                <div class="col-sm-10">
                  <input v-model="section.vod" class="form-control">
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">照片</label>
                <div class="col-sm-10">
                  <big-file v-bind:input-id="'image-upload'"
                            v-bind:text="'上传照片'"
                            v-bind:suffixs="['jpg', 'jpeg', 'png']"
                            v-bind:use="'album/picture'"
                            v-bind:after-upload="afterUploadPicture"></big-file>
                  <div v-show="section.url" class="row">
                    <div class="col-md-6">
                      <img v-bind:src="section.url" class="img-responsive" style="width:100%">
                    </div>
                  </div>
                </div>
              </div>


              <div   class="form-group">
                <label v-show ="section.media===MEDIA_TYPE.PICTURE.key" class="col-sm-6 control-label">照片URL</label>

                <label v-show ="section.media===MEDIA_TYPE.VIDEO.key" class="col-sm-6 control-label">视频封面URL</label>

<!--                <label class="col-sm-2 control-label">URL</label>-->
                <div class="col-sm-10">
                  <input v-model="section.url" class="form-control">
                </div>
              </div>



            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-on:click="save()" type="button" class="btn btn-primary">保存</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <modal-player ref="modalPlayer"></modal-player>

  </div>

</template>

<script>

import Pagination from "../../components/pagination";
import BigFile from "../../components/big-file";
import Vod from "../../components/vod";
import Player from "../../components/player";
import ModalPlayer from "../../components/modal-player";


export default {
    components: {ModalPlayer, Player, Pagination, BigFile, Vod},
    name: "business-section",
    data: function() {
      return {
        section: {},
        mediaselect: '',
        addoredit:'',
        chapter: {},
        sections: [],
        MEDIA_TYPE: MEDIA_TYPE,
        FILE_USE: FILE_USE,
        user:{},

      }
    },
    mounted: function() {
      let _this = this;
      _this.chapter = SessionStorage.get(SESSION_KEY_CHAPTER) || {};

      _this.user = this.$store.state.user;
      _this.$refs.pagination.size = 10;
      _this.list(1);
      // sidebar激活样式方法一
      // this.$parent.activeSidebar("business-section-sidebar");

    },
    methods: {
      /**
       * 点击【新增】
       */
      add() {
        let _this = this;
        if(_this.mediaselect === ''){
          alert('请先选择媒体类型')
          return
        }
        _this.addoredit='add';

        _this.section = {};
        _this.section.media =_this.mediaselect

        _this.section.charge =SECTION_CHARGE.FREE.key
        _this.section.charge =SECTION_CHARGE.FREE.key

        _this.section.courseId =_this.courseId;
        _this.section.chapterId =_this.chapterId;

        $("#form-modal").modal("show");
      },


      /**
       * 点击【编辑】
       */
      edit(section) {
        let _this = this;
        _this.addoredit='edit';

        _this.section = $.extend({}, section);
        $("#form-modal").modal("show");
      },

      /**
       * 列表查询
       */
      list(page) {
        let _this = this;
       Loading.show();
       // console.log("selectmedia,",_this.mediaselect);
        _this.$ajax.post(process.env.VUE_APP_SERVER + '/admin/section/list', {
          page: page,
          size: _this.$refs.pagination.size,
          courseId: _this.chapter.courseId,
          chapterId: _this.chapter.id,
          media: _this.mediaselect,

        }).then((response)=>{
          Loading.hide();
          let resp = response.data;
          _this.sections = resp.content.list;
          _this.$refs.pagination.render(page, resp.content.total);

        })
      },

      /**
       * 点击【保存】
       */
      save() {
        let _this = this;
        _this.section.video ="";
        // 保存校验
        if (1 != 1
          || !Validator.require(_this.section.title, "标题")
          || !Validator.length(_this.section.title, "标题", 1, 50)
          || !Validator.require(_this.section.media, "媒体")

        ) {
          return;
        }

        console.log(_this.section.media,"media")
        _this.section.submitter=_this.user.name
        _this.section.loginName = _this.user.loginName
        _this.section.charge ="F";
        _this.section.courseId= _this.chapter.courseId;
        _this.section.chapterId= _this.chapter.id;

        Loading.show();
        _this.$ajax.post(process.env.VUE_APP_SERVER + '/admin/section/save', _this.section).then((response)=>{
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            $("#form-modal").modal("hide");
            _this.list(1);
            Toast.success("保存成功！");
          } else {
            Toast.warning(resp.message)
          }
        })
      },

      /**
       * 点击【删除】
       */
      del(id) {
        let _this = this;
        Confirm.show("删除小节后不可恢复，确认删除？", function () {
          Loading.show();
          _this.$ajax.delete(process.env.VUE_APP_SERVER + '/admin/section/delete/' + id).then((response)=>{
            Loading.hide();
            let resp = response.data;
            if (resp.success) {
              _this.list(1);
              Toast.success("删除成功！");
            }
          })
        });
      },
      afterUploadVideo(resp) {
        let _this = this;
        let video = resp.content.path;
        let vod = resp.content.vod;
        _this.section.video = video;
        _this.section.vod = vod;
        _this.getTime();
        _this.$refs.player.playUrl(video);
        // 解决不能实时预览的问题
        _this.$forceUpdate();
      },

      afterUploadPicture(resp) {
        let _this = this;
        let image = resp.content.path;
        _this.section.url = image;
        // 解决不能实时预览的问题
        _this.$forceUpdate();
      },

      /**
       * 获取时长
       */
      getTime() {
        let _this = this;
        setTimeout(function () {
          let ele = document.getElementById("video");
          _this.section.time = parseInt(ele.duration, 10);
        }, 1000);
      },

      /**
       * 播放视频
       * @param section
       */
      play(section) {
        let _this = this;
        _this.$refs.modalPlayer.playVod(section.vod);
      }

    }
  }
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
  margin-top: 10px;
}
</style>
