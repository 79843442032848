<template>
  <main role="main">
    <div class="header-nav">
      <div class="clearfix">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <a v-on:click="onClickChapter('00000000')" id="chapter-00000000" href="javascript:;" class="cur">全部</a>
              <a v-for="o in chapters" v-on:click="onClickChapter(o.id)"
                 v-bind:id="'chapter-' + o.id"
                  href="javascript:;">{{o.name}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="skill clearfix">
      <div class="container">
        <div class="row">
          <div class="col-12">
             <a v-for="o in MEDIA_TYPE" v-on:click="onClickMediaType(o.key)" v-bind:id="'mediatype-' + o.key" href="javascript:;">{{o.value}}</a>
             <div style="clear:both"></div>
          </div>
        </div>
      </div>
    </div>


    <div v-show= "mediatype == MEDIA_TYPE.VIDEO.key"  class="album py-5 bg-light">
      <div class="container">


        <div class="row">

          <div v-for="o in videos" class="card col-md-4 shadow-sm course">
            <img class="img-fluid  "

                 :src="o.url  ? o.url :'/static/image/demo-course.jgp' "
                 @click="play(o)"
                 >
            <!--    <video v-bind:src="video.vod" id="video" controls="controls" class="hidden"></video>-->
            <div class="card-body">
              <!--     <h4 class="">{{video.name}}</h4>-->
              <p class="card-text">{{o.title}}</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <!--          <router-link v-bind:to="'/album?id=' + course.id" class="btn btn-outline-secondary">影集详情</router-link>-->
                </div>

              </div>
            </div>
          </div>


<!--          <div v-for="o in videos" class="col-md-4">-->
<!--          <the-video-->
<!--              v-bind:video="o"-->
<!--              v-bind:afterClick="afterClickVideo(o.vod)">-->

<!--          </the-video>-->
<!--          </div>-->
          <h3 v-show="videos.length === 0">还没有视频</h3>
        </div>
        <div class="row">

          <div class="col-md-12">
            <pagination ref="pagination1" v-bind:list="list"></pagination>
          </div>
        </div>
      </div>
    </div>


    <div v-show= "mediatype == MEDIA_TYPE.PICTURE.key"  class="album py-5 bg-light">
      <div class="container">
        <div class="row">

          <div class="col-md-12">
            <pagination ref="pagination2" v-bind:list="list"></pagination>
          </div>
        </div>
        <br>
        <div class="row">
          <BannerSlide :imageData="imageData" width="95%" height="600px" />

          <h3 v-show="pictures.length === 0">还没有照片</h3>
        </div>

      </div>


    </div>





    <modal-player ref="modalPlayer"></modal-player>
  </main>
</template>

<script>

  import ModalPlayer from "../components/modal-player";
  import TheVideo from "../components/the-video.vue";
  import ThePicture from "../components/the-picture.vue";

  import Pagination from "@/components/pagination.vue";
  import TheCourse from "@/components/the-course.vue";
  import BannerSlide from "@/components/swiper/BannerSlide.vue";

  export default {
    name: 'album',
    components: {TheCourse, Pagination, TheVideo, ThePicture, ModalPlayer, BannerSlide},
    data: function () {
      return {
        id: "",//courseid
        chapterid:"",
        chapters: [],
        videos: [],
        MEDIA_TYPE:MEDIA_TYPE,
        mediatype: MEDIA_TYPE.VIDEO.key,
        pictures: [],
        imageData: [],
        myHeight: '600 px'
      }
    },
    mounted() {
      let _this = this;
      _this.id = _this.$route.query.id;
  console.log ("albumid ", _this.id);
      _this.allChapters();
      _this.list(1);
      _this.handleResize()
      window.addEventListener('resize', _this.handleResize);



    },
    methods: {

      handleResize() {


        this.myHeight =  window.innerHeight-130  ;

        },


      allChapters() {
        let _this = this;
        _this.$ajax.get(process.env.VUE_APP_SERVER + '/web/chapter/all/' + _this.id)
            .then((response)=>{
          let resp = response.data;
          _this.chapters = resp.content||{};
            }).catch((response) => {
          console.log("error：", response);


        })
      },

      onClickMediaType(mt) {
        let _this = this;
        _this.mediatype = mt;


        // 点击一级分类时，二级分类【无限】按钮要设置激活状态
        if (mt == "V"){
          $("#mediatype-P").removeClass("on");

          $("#mediatype-V").addClass("on");
        }else if (mt == "P") {
          $("#mediatype-V").removeClass("on");

          $("#mediatype-P").addClass("on");
        }
        _this.list(1);
      },
      /**
       * 点击二级分类时
       * @param chapterId
       */
      onClickChapter(cId) {
        let _this = this;

        // 点击二级分类时，设置变量，用于课程筛选
        // 如果点击的是【无限】，则二级分类id为空
        if (cId === "00000000") {
          _this.chapterId = null;
        } else {
          _this.chapterId = cId;
        }


        // 点击一级分类时，显示激活状态
        $("#chapter-" + cId).siblings("a").removeClass("cur");
        $("#chapter-" + cId).addClass("cur");
        _this.list(1);
      },

      /**
       * 查询视频列表
       */
      list(page) {
        let _this = this;
        _this.imageData=[];

        _this.videos=[];
         _this.pictures=[];

        let pagesize =3;
        if(_this.mediatype === MEDIA_TYPE.VIDEO.key) {
          pagesize = _this.$refs.pagination1.size
        }else if( _this.mediatype === MEDIA_TYPE.PICTURE.key)
        {
          pagesize = _this.$refs.pagination2.size
        }
        Loading.show();

        _this.$ajax.post(process.env.VUE_APP_SERVER + '/web/section/list', {
          page: page,
          size: pagesize,
          chapterId: _this.chapterId || "", //
          courseId: _this.id, //
          media: _this.mediatype,
        }).then((response) => {
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            if(_this.mediatype === MEDIA_TYPE.VIDEO.key) {
              _this.videos = resp.content.list;

              _this.$refs.pagination1.render(page, resp.content.total);
            }else if( _this.mediatype === MEDIA_TYPE.PICTURE.key)
            {
              _this.pictures = resp.content.list;

              _this.$refs.pagination2.render(page, resp.content.total);
              for(let i = 0, len =_this.pictures.length; i<len; i++) {
                      let o = _this.pictures[i];
                      let image1={};
                      image1.title = o.name;
                      image1.imgUrl =o.url;
                      _this.imageData.push(image1);
              }



            }
          }
        }).catch((response) => {
          console.log("error：", response);
        })
      },

      //
      //
      // afterClickVideo(vod){
      //   let _this = this;
      //   console.log("afterclickvideo", vod)
      //   _this.$refs.modalPlayer.playVod(vod);
      // },
      /**
       * 播放视频
       * @param section
       */
      play(section) {
        let _this = this;

        _this.$refs.modalPlayer.playVod(section.vod);
      },



    },
    beforeDestroy() {

      window.removeEventListener('resize', this.handleResize);
    }


  }
</script>

<style>
  /* 课程信息 */
  .course-head {
  }
  .course-head h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  .course-head-item span {
    margin-right: 1rem;
  }
  .course-head-desc {
    font-size: 1rem;
    color: #555
  }
  .course-head a {
  }
  .course-head-price {
    font-size: 2rem;
  }
  @media (max-width: 700px) {
    .course-head h1 {
      font-size: 1.5rem;
    }
  }

  /* 章节列表 */
  .chapter {
    padding-bottom: 1.25rem;
  }
  .chapter-chapter {
    font-size: 1.25rem;
    padding: 1.25rem;
    background-color: #23527c;
    color: white;
    cursor: pointer;
  }
  .chapter-section-tr {
    font-size: 1rem;
  }
  .chapter-section-tr td{
    padding: 1rem 1.25rem;
    vertical-align: middle;
  }
  /*鼠标手势*/
  .chapter-section-tr td .section-title{
    color: #555;
  }
  .chapter-section-tr td .section-title:hover{
    color: #23527c;
    font-weight: bolder;
    cursor: pointer;
  }
  /*行头小图标*/
  .chapter-section-tr td .section-title i{
    color: #2a6496;
  }
  @media (max-width: 700px) {
    .chapter-chapter {
      font-size: 1.2rem;
    }
    .chapter-section-tr {
      font-size: 0.9rem;
    }
  }
</style>
<style>
/* 头部 一级分类 */
.header-nav {
  height: auto;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(28,31,33,.1);
  padding: 16px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  /*background-color: #d6e9c6;*/
}
.header-nav>div {
  width: 100%;
  padding-left: 12px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  /*background-color: #B4D5AC;*/
}
.header-nav a {
  float: left;
  font-size: 16px;
  color: #07111b;
  line-height: 50px;
  height: 45px;
  position: relative;
  margin-right: 46px;
  font-weight: 700;
}
.header-nav a:hover {
  color: #c80;
}
.header-nav a.cur {
  color: #c80;
}
.header-nav a.cur:before {
  display: block;
}
.header-nav a:before {
  display: none;
  content: ' ';
  position: absolute;
  bottom: 0;
  background: #c80;
  width: 16px;
  height: 3px;
  left: 50%;
  margin-left: -8px;
}
/* 二级分类 */
.skill {
  width: 100%;
  padding: 24px 0 0;
  position: relative;
  margin: 0 auto;
}
.skill a.on {
  color: #c80;
  background: rgba(204,136,0,.1);
}
.skill a {
  float: left;
  margin-right: 20px;
  padding: 0 12px;
  font-size: 14px;
  color: #4d555d;
  line-height: 32px;
  border-radius: 6px;
  margin-bottom: 12px;
}
.skill a:hover {
  background: #d9dde1;
}
</style>
