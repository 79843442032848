import Vue from 'vue'

import App from './app.vue'

import router from './router'
import axios from 'axios'
import filter from "./filter/filter";

import store from './store/index.ts'; // 引入 store 文件

Vue.config.productionTip = false;
Vue.prototype.$ajax = axios;

//事件总线EventBus
Vue.prototype.$event = new Vue();

 // 全局过滤器
Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key])
});

// 解决每次ajax请求，对应的sessionId不一致的问题
axios.defaults.withCredentials = true;

/**
 * axios拦截器
 */
axios.interceptors.request.use(function (config) {
  // let url =config.url;
  // let params =config.params;
  // console.log("in interceptor request  url ", url)
  // console.log(params)
  const token = store.state.user.token;

//每次request的时候记录地址，防止登录失效或者没有登录，等登录后自动跳转


  //let token = Tool.getLoginMember().token;
  if (Tool.isNotEmpty(token)) {
    config.headers.token = token;
    console.log("请求headers增加token:", token);
  }
  //config.headers.allowcr
  return config;
}, error => {});
axios.interceptors.response.use(function (response) {
  console.log("返回结果：", response);
  return response;
}, error => {
  console.log("返回拦截：", error.response);
  let response = error.response;
  const status = response.status;
  if (status === 401) {
    // 判断状态码是401 跳转到登录
    console.log("未登录，跳到登录页面");
    store.commit("setUser", {});
    Toast.error("请先登录或者没有权限")
    //Tool.setLoginMember(null);
    //router.push('/ssoframe');
  }
  // if (status === 417) {
  //   // 判断状态码是401 跳转到登录
  //   console.log("操作被拦截了网站暂不开放增删改操作");
  //   return {
  //     data: {
  //       success: false,
  //       message: "操作被拦截了，网站暂不开放增删改操作"
  //     }
  //   };
  // }
  return {
    data: {
      success: false,
      message: "请重新登录"
    }
  };
});

// 路由登录拦截
router.beforeEach((to, from, next) => {
  // 要不要对meta.loginRequire属性做监控拦截
  console.log("to", to)

  if (to.matched.some(function (item) {
    return item.meta.loginRequire
  })) {
    let loginUser = store.state.user;
    if (Tool.isEmpty(loginUser)) {
      //保存url，登录后直接跳转
      store.commit("setUser", {});
      store.commit("setToPath",to);
      console.log("login user is empty, need to login")
      //next('/ssoframe');
      Toast.error("请先登录")
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
   render: h => h(App),
}).$mount('#app');
