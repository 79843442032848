<template>
  <main role="main">

    <section class="jumbotron">
      <div class="container">
        <div class="rich_media_content " id="js_content" style="visibility: visible;">

          <p style="text-align: left;"><br></p>
          <p style="white-space: normal;"><span style="font-size: 20px;color: rgb(255, 104, 39);"></span></p>
          <section style="padding-top: 1px;padding-bottom: 1px;white-space: normal;font-size: 14px;">
            <h2
              style="margin-top: 5px;margin-bottom: 5px;padding-right: 10px;padding-left: 10px;font-weight: bold;border-left: 5px solid rgb(176, 125, 62);line-height: 32px;color: rgb(176, 125, 62);border-top-color: rgb(176, 125, 62);border-bottom-color: rgb(176, 125, 62);border-right-color: rgb(176, 125, 62);">
              <p style="border-color: rgb(176, 125, 62);"><span style="font-size: 18px;">公众号：甲蛙全栈</span></p></h2>
          </section>
          <p></p>
          <p style="white-space: normal;"><span style="font-size: 15px;letter-spacing: 1px;">12年Java老司机，专注Java全栈技术分享，汇总12年实战经验：</span><br>
          </p>
          <p style="white-space: normal;"><span style="font-size: 15px;letter-spacing: 1px;">前端、后端、数据库、中间件、部署<br></span>
          </p>
          <p style="white-space: normal;"><span style="font-size: 15px;letter-spacing: 1px;">Vue,&nbsp;Bootstrap, SpringBoot, Mybatis, Mysql, Redis,&nbsp;MQ</span>
          <p style="white-space: normal;"><span style="font-size: 15px;letter-spacing: 1px;">微信号：jiawa1986</span>
          </p>
          <img style="width: 180px" src="/static/image/公众号甲蛙全栈二维码.jpg">
        </div>
      </div>
    </section>
  </main>
</template>

<script>

  export default {
    name: 'about',
  }
</script>

<style scoped>
  .jumbotron {
    padding-top: 0 !important;
  }
</style>
