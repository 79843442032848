<template>
  <div class="card mb-4 shadow-sm course">
    <img class="img-fluid" style="width:100%; cursor: pointer;" v-bind:src="picture.url"

    >
    <div class="card-body">
<!--      <h4 class="">{{picture.name}}</h4>-->
      <p class="card-text">{{picture.title}}</p>
      <div class="d-flex justify-content-between align-items-center">
        <div class="btn-group">
<!--          <router-link v-bind:to="'/album?id=' + course.id" class="btn btn-outline-secondary">影集详情</router-link>-->
        </div>

      </div>
    </div>

    <PhotoModal :photoUrl="picture.url" ref="photoModal" />
  </div>
</template>

<script>
  import PhotoModal from "@/components/PhotoModal.vue";

  export default {
    name: 'the-picture',
    components:{
      PhotoModal,
    },
    props: {
      picture: {}
    },
    data: function () {
      return {

      }
    },


    methods: {
      showModal() {
        this.$refs.photoModal.showModal();
      },
    },

  }
</script>

<style>
  .course h4 {
    font-size: 1.25rem;
    margin: 15px 0;
  }

  .course .text-muted .badge {
    font-size: 1rem;
  }



</style>