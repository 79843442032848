import Vue from 'vue';
import Vuex from 'vuex';
 // 该指令必须在 store 创建之前执行
Vue.use(Vuex);


const USER = "USER";
const TOPATH  ='TOPATH';


const store =new Vuex.Store({
  state: {
    user: SessionStorage.get(USER) || {},
    toPath:  SessionStorage.get(TOPATH) || {},

  },
  mutations: {


    setUser (state, user) {
      console.log("store user：", user);
      state.user = user;
      SessionStorage.set(USER, user);

    },
    setToPath (state, tpath ){

      state.toPath = tpath;
      SessionStorage.set(TOPATH, tpath)
    },



  },
  actions: {
  },
  modules: {
  }
});

export default store;
