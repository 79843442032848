<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container">
        <a class="navbar-brand" href="#">
          <i class="ace-icon fa fa-video-camera"></i>&nbsp;UAVEE 影集
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <router-link class="nav-link" to="/">主页</router-link>
            </li>

            <li class="nav-item active">
              <router-link to="/admin/category"  class="nav-link" v-show="hasResource('0201')"  >管理分类</router-link>
            </li>

            <li class="nav-item active">
              <router-link to="/admin/course" class="nav-link" v-show="hasResource('0202')" >管理影集</router-link>
            </li>

           </ul>

          <span  class="text-white pr-3"></span>

          <button v-show="!user.id" v-on:click="login()" class="btn btn-outline-light my-2 my-sm-0">登录/注册</button>

          <button v-show="user.id" class="nav-item dropdown active">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              您好：{{user.name}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">


              <div v-show="user.id" > <label  v-on:click="logout()" class="dropdown-item">退出登录 </label></div>
            </div>
          </button>
        </div>
      </div>
    </nav>
    <div   v-show="isLogin"   >

      <iframe
          ref="iframe"
          :src="loginUrlToSso"
          width="100%"
          height="80px"


      ></iframe>

    </div>

    <div      v-show ="isLogout">

      <iframe
          ref="iframe1"
          :src="logoutUrlToSso"
          width="100%"
          height="60px"

      ></iframe>

    </div>
    <iframe
        ref="iframe2"
        :src="checkUrlFromSso"
        width="0px"
        height="0px"
        style="display: none;"
    ></iframe>


<!--    <the-login ref="loginComponent"></the-login>-->
  </header>
</template>

<script>
import {mapMutations, mapState} from 'vuex';


import store from "@/store/index.ts";

// const iframe = ref<any>(null)
//
// const iframe1 = ref<any>(null)
// const iframe2 = ref<any>(null)


const ssoUrl = 'http://user.uav-eco-env.net';
const loginUrlToSso = '';
const logoutUrlToSso = '';
const checkUrl_Org = ssoUrl+"/check";
const checkUrlFromSso = "";

let autologin =false;

let isLogin = false
let  isLogout= false;

 export default {
    name: 'theHeader',

    components: {
    //  TheLogin


    },
    data: function () {
      return {

          isLogin : this.isLogin,
          isLogout: this.isLogout,
        loginUrlToSso: this.loginUrlToSso,
        logoutUrlToSso: this.logoutUrlToSso,
        checkUrlFromSso: this.checkUrlFromSso,
      }
    },
   computed: {
      user(){
        return this.$store.state.user;
      }
   },


    mounted() {
     // let _this = this;
     // _this.loginMember = Tool.getLoginMember();
    //  console.log("loginMemeber info from mounted  ", _this.loginMember)
      window.addEventListener('message', this.handlePostMessage,false);
      this.check(false);

    },

   methods: {

     ...mapMutations((['setUser'])),

     /**
       * 打开登录注册窗口
       */


     handlePostMessage (event) {
       let _this =this;
       console.log("从",event.origin,"获得信息")
       if (event.origin !== 'http://user.uav-eco-env.net') return


       console.log("获得登录页面post信息", event.data)
       if (event.data.type === 'login_complete') {
         // 处理登录完成的事件
         console.log('登录成功', event.data);
         // 在这里可以执行需要的操作，比如关闭iframe
         const indata =event.data;
         //console.log("userInfo",indata)


         if(!Tool.isEmpty(indata)) {

           store.commit("setUser", indata.data);

           //   iframe.value.style.display = 'none';
         }
         _this.isLogin =false;
         _this.isLogout=false;
         //
       }

       if(event.data.type === 'logout_complete'){
         console.log('登出成功', event.data);
         const indata =event.data;

         if(indata.data.status === 'confirm') {
           // if(indata.data.loginName !== undefined){
           //   if(user.value.loginName === indata.data.loginName ){
           store.commit("setUser", {});
           //  }
           //}
         }
         _this.isLogin =false;
         _this.isLogout=false;
         //

       }

       if (event.data.type === 'check_complete') {
         // 处理登录完成的事件

         // 在这里可以执行需要的操作，比如关闭iframe
         const indata = event.data;
         console.log("get userInfo from check and save ",indata)

         if(indata.data ===undefined || indata.data === null) {
           // console.log("userInfo undefined")
           //其他二级域名已经logout
           store.commit("setUser", {});
           if(autologin) login();
         }else{
           if (!Tool.isEmpty(indata)) {
             //save login user into SessionStorage
             store.commit("setUser", indata.data);
           }
         }
       }



     },

      check  (autologin1){
        let _this =this;
       console.log("开始从登录页面检查登录信息");
       _this.autologin = autologin1;
       //autologin true 自动登录； false 仅检查有登录信息拷贝到local，没有登录信息则不自动登录
       //改变URL，会自动重新加载？
       _this.checkUrlFromSso = checkUrl_Org + '?reload=' + new Date().valueOf();
     },

     // 登录
      login ()  {
       console.log("开始登录");
      let _this =this;
       //先检查有没有
       _this.loginUrlToSso =   ssoUrl+"/login"
       // iframe.value.style.display = 'block';
       // iframe1.value.style.display = 'none';
       _this.isLogin=true;
       _this.isLogout=false;

     },

     // 退出登录
      logout  () {
        let _this =this;
       _this.logoutUrlToSso =   ssoUrl+"/logout"
       // iframe1.value.style.display = 'block';
       // iframe.value.style.display = 'none';

       _this.isLogin=false;
       _this.isLogout =true;


     },



      // setLoginUser(){
      //    this.loginMember =Tool.getLoginMember();
      // },

     /**
      * 查找是否有权限
      * @param id
      */
     hasResource(id) {
       let _this =this;
       console.log("Checking resource", id)
       let user1 =_this.user;

       if(Tool.isNotEmpty(user1.resources)) {
         return Tool.hasResource(id, user1.resources);
       }else{
         return false;
       }

     },


    },

   beforeDestroy() {
     window.removeEventListener('message', this.handlePostMessage);
   }

  }
</script>
