<template>
  <div class="card mb-4 shadow-sm course">
   <img class="img-fluid" style="width:100%"
        @click="play"
        v-bind:src="video.url ?  video.url:'/static/image/demo-course.jgp' ">
<!--    <video v-bind:src="video.vod" id="video" controls="controls" class="hidden"></video>-->
    <div class="card-body">
<!--     <h4 class="">{{video.name}}</h4>-->
      <p class="card-text">{{video.title}}</p>
      <div class="d-flex justify-content-between align-items-center">
        <div class="btn-group">
<!--          <router-link v-bind:to="'/album?id=' + course.id" class="btn btn-outline-secondary">影集详情</router-link>-->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'the-video',
    props: {
      video: {},
      afterClick: {
        type: Function,
        default: null
      },
    },
    data: function () {
      return {
      }
    },
    methods: {
      play() {
        let _this =this;
        _this.afterClick;
      },
    },

  }
</script>

<style>
  .course h4 {
    font-size: 1.25rem;
    margin: 15px 0;
  }

  .course .text-muted .badge {
    font-size: 1rem;
  }
</style>
