<template>
  <div>
    <the-header></the-header>

    <router-view></router-view>

    <the-footer></the-footer>
  </div>
</template>

<script>

import TheHeader from "./components/the-header";
import TheFooter from "./components/the-footer";
export default {
  name: 'app',
  components: {TheFooter, TheHeader},

  // watch: {
  //   // 监听路由对象$route的变化
  //   '$route': {
  //     immediate: true,
  //     handler(to, from) {
  //       // 根据路由变化，更新someData
  //
  //       if(from.fullPath!== undefined){
  //
  //       if(from.fullPath ==='/ssoframe'){
  //         this.$router.go(0)
  //
  //       }
  //       }
  //     }
  //   }
  // },
  methods: {
    // refreshHeader( ) {
    //
    // }
  }

}
</script>
