import { render, staticRenderFns } from "./gallary.vue?vue&type=template&id=fdf4e88e"
import script from "./gallary.vue?vue&type=script&lang=js"
export * from "./gallary.vue?vue&type=script&lang=js"
import style0 from "./gallary.vue?vue&type=style&index=0&id=fdf4e88e&prod&lang=css"
import style1 from "./gallary.vue?vue&type=style&index=1&id=fdf4e88e&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports