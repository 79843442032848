<template>
  <footer class="text-muted">
    <div class="container">
      <p class="float-right">
        <a href="#">回到顶部</a>
      </p>
      <strong>Copyright &copy; 2018 <a href="http://uav-eco-env.net">UAVEE</a></strong>
      <a href="http://www.miitbeian.gov.cn/" class="text-black" >苏ICP备18069774号-1</a>
    </div>
  </footer>
</template>

<script>

  export default {
    name: 'theFooter',
  }
</script>
